import { Analytics } from '@vercel/analytics/react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div class="bg-gray-100 text-gray-800 flex flex-col items-center justify-center h-screen">
        <div class="flex-grow flex flex-col items-center justify-center text-center">
          <h1 class="text-3xl font-bold mb-4">Can I Has API Key?</h1>
          <p class="text-lg mb-8">A simple place to find and share API keys.</p>
          <a href="https://docs.google.com/spreadsheets/d/1PZHwsN8eT_xH13KWARs1wbWoklxOZNLIfVKni8ve2HU/edit?usp=sharing" target="_blank" class="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
              View API Keys
          </a>
        </div>
        <footer class="w-full text-center text-gray-600 py-4">
          Made with <span class="text-red-500">&hearts;</span> in Canada
        </footer>
        <Analytics></Analytics>
      </div>
    </div>
  );
}

export default App;
